<template>
  <b-container>
    <b-row class="mb-5">
      <b-col sm="9" md="6" lg="9" cols="12">
        <h1 class="main-heading">{{ $t("heading.our_clients") }}</h1>
      </b-col>
      <b-col sm="3" md="6" lg="3" cols="12">
        <Button
          class="button button--sm button--outline-primary mx-0 width-100"
          to="/clients"
          :text="$t('button.see_all')"
        ></Button>
      </b-col>
    </b-row>
    <b-row v-if="!fetchLoading && clients.length > 0">
      <b-col cols="12">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="(item, index) in clients" :key="index">
            <client-card :item="item"></client-card>
          </swiper-slide>
        </swiper>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { IndexData } from "@/helpers/apiMethods";
import ClientCard from "@/components/Cards/Client";
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";

export default {
  name: "ClientsSlider",
  components: {
    ClientCard,
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: true,
        slidesPerView: 5,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        breakpoints: {
          1440: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          769: {
            slidesPerView: 1
          },
          560: {
            slidesPerView: 1
          }
        }
      },
      clients: [],
      fetchLoading: false
    };
  },
  mounted() {
    this.fetchClients();
  },
  methods: {
    async fetchClients() {
      try {
        this.fetchLoading = true;
        const { data } = await IndexData({
          reqName: "clients"
        });
        this.clients = data.clients;
        this.pagination = data.meta;
      } catch (err) {
        console.log(err);
      } finally {
        this.fetchLoading = false;
      }
    }
  }
};
</script>

<style>
.width-100 {
  width: 100% !important;
}
</style>
